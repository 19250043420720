import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";
import { ReactComponentElement, SyntheticEvent, forwardRef, useState } from "react";

type ScrubbableVideoProps = {
    video: {
        id: string;
        src: any;
        onLoadedMetadata: any;
        autoPlay: boolean;
        loop: boolean;
        muted: boolean;
        style?: React.CSSProperties;
        handleMetaData?: any;
        className?: string;
    };
    slider: {
        style?: React.CSSProperties;
        onFocus?: any;
        onChange?: any;
        hidePlay?: boolean;
        center?: boolean;
        hide?: boolean;
    };
    cropper?: ReactComponentElement<any>;
    onSetFallbackFrame: any;
};

export const ScrubbableVideo = forwardRef<HTMLVideoElement, ScrubbableVideoProps>((props, ref) => {
    const [autoUpdating, setAutoUpdating] = useState<boolean>(true);
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const [videoPercentage, setVideoPercentage] = useState<number>(0);

    const getVideoPercentage = (videoElement: HTMLVideoElement): number => {
        const time = videoElement.currentTime;
        const duration = videoElement.duration * 1000;
        const timePercentage = (time / duration) * 1000 * 100;
        return timePercentage;
    };
    const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
        if (e.target instanceof HTMLVideoElement) {
            if (autoUpdating) {
                const time = e.target.currentTime;
                const duration = e.target.duration * 1000;
                const timePercentage = (time / duration) * 1000 * 100;
                setVideoPercentage(timePercentage);
            }
        }
    };

    const handlePlayPause = () => {
        const video = document.getElementById(props.video.id) as HTMLVideoElement;
        if (isPlaying) {
            video.pause();
            setIsPlaying(false);
        } else {
            video.play();
            setIsPlaying(true);
        }
    };

    const handleSliderChange = (value: number | number[]) => {
        const video = document.getElementById(props.video.id) as HTMLVideoElement;
        setAutoUpdating(false);
        isPlaying && video.pause();
        const duration = video.duration * 1000;
        const newTime = (duration * (value as number)) / 100;
        console.log(newTime / 1000);
        video.currentTime = newTime / 1000;
        setVideoPercentage(value as number);
    };

    const handleSetFallbackFrame = () => {
        const video = document.getElementById(props.video.id) as HTMLVideoElement;
        const fallbackFrameOffset = video.currentTime >= video.duration ? video.duration - 0.01 : video.currentTime;

        props.onSetFallbackFrame(fallbackFrameOffset);
    };

    const handleChangeComplete = () => {
        const video = document.getElementById(props.video.id) as HTMLVideoElement;
        props.video.autoPlay && isPlaying && video.play();
        isPlaying && setAutoUpdating(true);
    };
    return (
        <div className="scrubbable-video">
            <div className="video-container" style={props.video.style}>
                {props.cropper}
                <video
                    id={props.video.id}
                    src={props.video.src}
                    onLoadedMetadata={props.video.onLoadedMetadata}
                    autoPlay={props.video.autoPlay}
                    loop={props.video.loop}
                    style={props.video.style}
                    muted={props.video.muted}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadataCapture={props.video.handleMetaData}
                    ref={ref}
                    className={props.video.className || ""}></video>
            </div>
            <div className={`row ${props.slider.center ? "centered" : ""}`}>
                {!props.slider.hidePlay && (
                    <span className="play-btn" onClick={handlePlayPause}>
                        {isPlaying && <BsFillPauseFill size={24} style={{ transform: "translateY(-.2rem)" }} />}
                        {!isPlaying && <BsFillPlayFill size={24} style={{ transform: "translateY(-.2rem)" }} />}
                    </span>
                )}

                {!props.slider.hide && (
                    <Slider
                        style={props.slider.style}
                        value={videoPercentage}
                        onFocus={props.slider.onFocus}
                        onChange={handleSliderChange}
                        onChangeComplete={handleChangeComplete}
                    />
                )}
            </div>
        </div>
    );
});
